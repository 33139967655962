
























import { defineComponent, PropType, ref } from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import ExtractFormSelect from '@/components/organisms/h/item/formSelect/ExtractFormSelect.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import ExtractConditionRowLayout from '@/components/organisms/h/layout/ExtractConditionRowLayout.vue'
import {
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  ItemFeatureExtractCondition,
} from '@/types/entities'
import { ItemFeatureExtractAutoorderTypeOptions } from '@/utils/constant'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    ExtractConditionRowLayout,
    ItemHierarchyGroup,
    HLine,
    ExtractFormSelect,
  },
  props: {},
  setup(_, context) {
    const itemUseList = ref<itemHierarchyUseList>([['Shobunrui']])
    const extractConditionOfItem = ref<itemHierarchyGroupExtractCondition>({})
    // 発注方式のセレクト部品の初期値とOption
    const options = ItemFeatureExtractAutoorderTypeOptions
    const defaultSelected = null
    let extractAutoorderType = ''
    /**
     * 抽出条件変更イベント
     */
    const changeExtractCondition = () => {
      const base: ItemFeatureExtractCondition = {
        autoorderType: extractAutoorderType || '',
        shobunrui: extractConditionOfItem.value.shobunrui || [],
        shobunruiCount: extractConditionOfItem.value.shobunruiCount || 0,
      }
      let cond = { ...base }
      context.emit('change-extract-condition', cond)
    }

    const changeExtractConditionOfItem = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItem.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfAutoorderType = (param: string) => {
      console.log('changeExtractConditionOfAutoorderType', param)
      extractAutoorderType = param
      changeExtractCondition()
    }

    return {
      defaultSelected,
      options,
      itemUseList,
      changeExtractConditionOfItem,
      changeExtractConditionOfAutoorderType,
    }
  },
})
