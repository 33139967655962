


















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/common/item-feature/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import {
  ItemFeatureOutputCondition,
  ItemFeatureExtractCondition,
  ColumnLabel,
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPItemFeaturesCountDocument,
} from '@/types/generated/graphql'
import { EXPORT_FILENAME } from '@/utils/constant'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  itemOutput: [
    OUTPUT_COND_ITEM.all,
    OUTPUT_COND_ITEM.shobunrui,
    OUTPUT_COND_ITEM.tanpin,
  ],
  delOutput: [],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPItemFeature,
      templateFile: TemplateFile.UiPItemFeature_01,
      extractCondition: {
        shobunrui: [],
        autoorderType: '',
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Item',
          displayItems: [
            OUTPUT_COND_ITEM.all,
            OUTPUT_COND_ITEM.shobunrui,
            OUTPUT_COND_ITEM.tanpin,
          ],
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: [],
        },
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    changeExtractCondition(cond: ItemFeatureExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: ItemFeatureOutputCondition) {
      console.log('index_changeOutputCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      this.totalCount = null
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPItemFeaturesCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPItemFeatures.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      // 選択数上限チェック
      if (this.extractCondition.shobunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.shobunrui'),
            selected: this.extractCondition.shobunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    /**
     * エクスポート処理
     */
    //  async execExport(input: ExportCondition) {
    async requestExport() {
      if (this.checkRequired(true)) {
        const query = this._create_query_string()
        await this.helper.export({
          query,
          jmespathQuery:
            'data.vUiPItemFeatures.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.item_feature,
          columnLabelMapping: this._create_column_label_mapping(),
        })
      }
    },

    /**
     * ファイル抽出Queryを構築する
     */
    _create_query_string() {
      const where = this._createWhere()
      const orderBy = this._createOrderByStatement()
      const rowQuery = `
        query c {
          vUiPItemFeatures(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                inactiveFlg
                itemApplyLevel
                itemApplyScope
                vUiMItemApplyLevel {
                  itemApplyScopeName
                }
                autoorderType
                autoorderTypeName
                configuredFlg
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'itemApplyLevel',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'itemApplyScope',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
    _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 小分類の抽出条件
        {
          field: ['vUiMItemApplyLevel/uiMCategory1s/category1Cd'],
          op: WhereOp.In,
          value: [''],
        },

        // 発注方式の抽出条件
        {
          field: ['autoorderType'],
          op: WhereOp.Eq,
          value: [''],
        },
        // 出力条件
        { field: ['itemApplyLevel'], op: WhereOp.In, value: [''] },
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
        // 設定行フラグ　1固定
        { field: ['configuredFlg'], op: WhereOp.In, value: ['1'] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(
        where,
        'vUiMItemApplyLevel/uiMCategory1s/category1Cd',
        this.extractCondition.shobunrui
      )
      setWhereValue(where, 'autoorderType', this.extractCondition.autoorderType)
      setWhereValue(where, 'itemApplyLevel', this.outputCondition.itemOutput)
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log({ where })
      return where
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'itemApplyLevel',
          to: '',
        },
        {
          from: 'itemApplyLevelName',
          to: '',
        },
        {
          from: 'itemApplyScope',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.itemApplyScopeName',
          to: '',
        },
        {
          from: 'autoorderType',
          to: '',
        },
        {
          from: 'autoorderTypeName',
          to: '',
        },
        {
          from: 'configuredFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
