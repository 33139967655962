
















export default {
  data() {
    return {
      selected: this.defaultSelected,
    }
  },
  props: ['options', 'defaultSelected'],
  methods: {
    changeSelected(v: string) {
      console.log(v)
      this.$emit('changeSelected', v)
    },
  },
}
