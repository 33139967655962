











import { defineComponent, PropType, ref } from '@vue/composition-api'

import OutputConditionGroup from '@/components/organisms/h/outputCond/OutputConditionGroup.vue'
import {
  ItemFeatureOutputCondition,
  outputConditionGroupOutputCondition,
  outputCondUseInfoList,
} from '@/types/entities'

export default defineComponent({
  components: {
    OutputConditionGroup,
  },
  props: {
    outputUseList: {
      type: Array as PropType<outputCondUseInfoList>,
      default: () => [],
    },
  },
  setup(_, context) {
    const changeOutputCondition = (
      params: outputConditionGroupOutputCondition
    ) => {
      const cond: ItemFeatureOutputCondition = {
        itemOutput: params.itemOutput || [],
        delOutput: params.delOutput || [],
      }
      context.emit('change-output-condition', cond)
    }
    const radioDisabled = true
    return { changeOutputCondition, radioDisabled }
  },
})
